import { motion } from 'framer-motion'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import delay from '../../../utils/reuseable/delay'
import TranslateText from '../../../utils/translateText'
import { greytext } from '../../styledComponents'
import { BsArrowDownShort } from 'react-icons/bs'


const LinkSpan = styled.div`
    color: #f1f1f190;
    font-size: 1.2rem;
    width: 20px;
    height: 20px;
    display: inline-block;
    padding-left: 5px;
    line-height: 100%;
    transform: rotate(-135deg);
`;



const OuterContainer = styled(motion.div)`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    width: 32vw;
    max-width: 400px;
    height: 260px;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.02),0 4px 6px rgba(0,0,0,.02),inset 0 0 0 6px #111;
    background: linear-gradient(180deg,#242424,#121212 65.62%);
    position: relative;
    transition: background .15s ease;

    &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: inherit;
        padding: 1.2px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        background: conic-gradient(from 180deg at 50% 50%,#333  0deg,#333 ${props => props.colorsize.start}deg, 
            ${props => props.color}, ${props => props.colorsize.end}deg,#333 217deg,#333  1turn);
    }

    &:hover{
        background: linear-gradient(180deg,#242424,hsla(0,0%,100%,.02) 65.62%);
        cursor: pointer;
    }


    @media (max-width: 1300px)  {
        width: 28vw;
    }

    @media (max-width: 1000px)  {
        width: 95vw;
        max-width: 100%;
    }

    @media (max-width: 700px)  {
        height: 280px;
    }

`;

const BoxTitle = styled.span`
    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 4px;
    transition: all 0.2s;
    letter-spacing: 1px;
    line-height: 120%;
    color:#f1f1f1;
    text-align: left;
    width: 100%;
    white-space: nowrap;

    @media (max-width: 550px)  {
        font-size: 1.3rem;
        line-height: 1.4rem;
    }
`

const BoxTitleLower = styled.span`
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 4px;
    transition: all 0.2s;
    letter-spacing: 1px;
    margin-bottom: 0.8rem;
    line-height: 120%;
    color:#f1f1f1;
    text-align: left;
    width: 100%;

    @media (max-width: 550px)  {
        font-size: 1.3rem;
        line-height: 1.4rem;
    }
`

const BoxText = styled.span`
    font-size: 0.9rem;
    line-height: 140%;
    font-weight: 400;
    letter-spacing: 1.5px;
    //color:white;
    color: ${greytext};
    text-align: left;
    width: 100%;

    @media (max-width: 550px)  {
        font-size: 0.9rem;
        line-height: 1.3rem;
    
    }
`

const ImageContainer = styled.div`
  position: relative;
  width: 130px;
  margin-bottom: 1.5rem;
  height:100%;
  transform: translate3d(0px, ${props => props.translate}, 0px);
  transition: 1s;

  @media (max-width: 1000px)  {
    height:110px 
   }

  @media (max-width: 700px)  {
        height:100px 
   }


`;

const ColumnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  
`;

export default function RowItem({ item, animationControls }) {
    const [height, setHeight] = useState(0)

    const [colorsize, setcolorsize] = useState({
        start: 150 + Math.random() * 10, // Adjust this range as per your preference
        end: 190 + Math.random() * 10   // Adjust this range as per your preference
    });

    async function setMove() {
        const newHeigt = Math.random() * 2
        await delay(500 + Math.random() * 10000)
        setHeight(newHeigt)
        await delay(500 + Math.random() * 10000)
        setHeight(0)
    }

    useEffect(() => {
        setMove()
    }, [height])

    const variants = {
        hidden: item?.hidden,
        visible: item?.visible
    }

    return (
        <OuterContainer
            initial={"hidden"}
            animate={animationControls}
            variants={variants}
            viewport={{ once: false }}
            color={item.color}
            colorsize={colorsize}
        >
            <ImageContainer translate={`${height}px`}>
                <Image
                    src={item?.image}
                    fill
                    style={{ objectFit: "contain" }}
                    alt={item?.alt}
                />
            </ImageContainer>
            <ColumnWrap>
                <BoxTitle>
                    <TranslateText
                        swedish={item?.titleSwe}
                        english={item?.titleEng}
                    />

                </BoxTitle>
                <BoxTitleLower>
                    <TranslateText
                        swedish={item?.titleBottomSwe}
                        english={item?.titleBottomEng}
                    />
                    <LinkSpan>
                        <BsArrowDownShort />
                    </LinkSpan>
                </BoxTitleLower>
            </ColumnWrap>

            <BoxText>
                <TranslateText
                    swedish={item?.textSwe}
                    english={item?.textEng}
                />
            </BoxText>
        </OuterContainer>
    )
}
