import { motion, useAnimationControls, useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import TranslateText from '../../utils/translateText';
import { TitleLeft, TitleSmall } from '../styledComponents';
import RowItem from './components/rowItem';
import { OuterDiv } from '../styledComponents';
import Link from 'next/link';
import { linkProductAnalytics, linkProductAutomation, linkProductOptimization} from '../../Layout/links';


const RowWrap = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  height: fit-content;
  overflow: visible;
  margin-top: 2rem;
  
  @media screen and (max-width:1000px) {
    gap:25px;
    flex-direction: column;
    justify-content:flex-start;
    margin-bottom: 3rem;
    margin-top: 5vh;
  }
`;

const Textwrap = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  height: fit-content;
  overflow: visible;

  @media screen and (max-width:950px) {
    flex-direction: column;
    justify-content:flex-start;
    margin-bottom: 1rem;
    //margin-top: max(3vh, 1.5rem);
  }
`;

const rowItemData = [
  {
    image: '/rowItems/Planning.png',
    alt: 'Image describing warehouse stacking of items',
    titleSwe: 'Lagerplanering ',
    titleEng: 'Inventory planning ',
    titleBottomSwe: 'med AI',
    titleBottomEng: 'with AI ',
    textSwe: ' Med rätt plats och grupperingar av lagerinventarierna minskas plocktiderna drastiskt.',
    textEng: ' Optimize the location of your inventory. With the right placement and groupings, picking times can be drastically reduced.',
    color: '#61dafb',
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.3, delay: 0 } },
    hidden: { opacity: 0, scale: 1, y: 100 },
    link: linkProductOptimization
  },
  {
    image: '/rowItems/Automation.png',
    alt: 'Image describing automation',
    titleSwe: 'Automatiserad ',
    titleEng: 'Automatic',
    titleBottomSwe: 'Uppgiftsfördelning',
    titleBottomEng: ' task delegation ',
    textSwe: `Med en datadriven uppgiftshantering minimeras flaskhalsar, minskar fel och ökar den totala produktiviteten`,
    textEng: 'With data driven task management bottlenecks are minimized, erros are reduced and total productivity improves.',
    color: '#FF4D5A',
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.3, delay: 0.2 } },
    hidden: { opacity: 0, scale: 1, y: 100 },
    link: linkProductAutomation
  },
  {
    image: '/rowItems/Analytics.png',
    alt: 'Image describing analytics with AI and Big Data,',
    titleSwe: 'Processanalys ',
    titleEng: `Process analytics`,
    titleBottomSwe: 'med Big Data & AI',
    titleBottomEng: 'with Big Data & AI ',
    textSwe: 'Med en detaljanalys av datan kan nuvarande ineffektiviteter åtgärdas och förebyggas.',
    textEng: 'Detailed task insights enhance forecasts, swiftly address inefficiencies, and mitigate uncertainties.',
    color: '#64FFDA',
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.3, delay: 0.4 } },
    hidden: { opacity: 0, scale: 1, y: 100 },
    link: linkProductAnalytics,
  }
]

function ExampleSection({ }) {
  const ref = useRef(null)
  const rowRef = useRef(null)
  const inView = useInView(ref, { once: true, margin: "0px 0px 100px 0px" })
  const rowInView = useInView(rowRef, { once: true, margin: "0px 0px 100px 0px" })
  const rowAnimationControls = useAnimationControls()
  const animationControls = useAnimationControls()

  useEffect(() => {

    if (typeof window !== "undefined") {
      let width = window.innerWidth;
      if (inView || width < 600) {
        animationControls.start("visible");
      }

      if (rowInView || width < 600) {
        rowAnimationControls.start("visible");
      }
    }


  }, [inView, rowInView]);

  const variants = {
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.4, delay: 0 } },
    hidden: { opacity: 0.2, scale: 0.9, y: 100 },
  }

  return (
    <OuterDiv
      initial={"hidden"}
      animate={animationControls}
      variants={variants}
      viewport={{ once: false }}
      ref={ref}
      minheight={'600px'}
    >
      <TitleSmall leftAligned>
        <TranslateText swedish={'Hur används AlgoFlow?'} english={'How is AlgoFlow used?'} />
      </TitleSmall>

      <Textwrap>
        <TitleLeft >
          <TranslateText swedish={'Paretro är verktygslådan för att optimera era logistik och industriresurser.'}
            english={'Paretro provides the toolbox for optimizing your existing warehouse and production resources '} />
        </TitleLeft>
      </Textwrap>
      <RowWrap ref={rowRef} >
        {rowItemData.map((item) => {
          return (
            <Link href={item.link} key={item.title}>
              <RowItem item={item}  animationControls={rowAnimationControls} />
            </Link>
          )
        })}
      </RowWrap>
    </OuterDiv>
  )
}
//  DataSlider items={items} />

export default ExampleSection;